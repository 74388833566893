import {
  CardLiveFeedEntityBottomSlotProps,
  CardLiveFeedEntityItemProps,
  CardLiveFeedEntityTagsProps,
  CardLiveFeedProps,
} from '@entities/desktop/cards/card-live-feed/ui/card-live-feed.types';
import { CardLiveFeedEntityItemInfo } from '@entities/desktop/cards/card-live-feed/ui/item-info';
import cn from 'clsx';

import { Item, TagRarity } from '@/shared/ui';
import { ItemRarityLiveFeed } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';
import { TagCategory } from '@/shared/ui/tag-category';

import styles from './card-live-feed.module.scss';

export const CardLiveFeedEntity = ({
  children,
  className,
  tagCategory,
  item,
  rarity,
  info,
  topSlot = <CardLiveFeedEntity.Item rarity={rarity} item={item} />,
  bottomSlot = (
    <CardLiveFeedEntity.BottomSlot
      colorScheme={
        itemVariantsMM2.includes(item) ? colorsMM2Variant : colorsByVariant
      }
      tags={tagCategory}
      rarity={rarity}
      withRarity={itemVariantsMM2.includes(item)}
    />
  ),
  ...props
}: CardLiveFeedProps) => {
  const cardLiveFeedClasses = cn(styles['card-live-feed'], className);

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <div className={cn(styles['card-live-feed--content'])}>
        <div className={cn(styles['card-live-feed--content__info'])}>
          <CardLiveFeedEntityItemInfo info={info} />
        </div>
        <div className={cn(styles['card-live-feed--content__item'])}>
          {topSlot}
        </div>
        {tagCategory && (
          <CardLiveFeedEntity.Tags isRarity={itemVariantsMM2.includes(item)}>
            {bottomSlot}
          </CardLiveFeedEntity.Tags>
        )}
      </div>
    </article>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Tags = ({
  children,
  isRarity,
}: CardLiveFeedEntityTagsProps) => {
  const cardLiveFeedEntityTagsClasses = cn(styles['card-live-feed--tags'], {
    [styles['card-live-feed--tags-rarity']]: isRarity,
  });

  return <div className={cardLiveFeedEntityTagsClasses}>{children}</div>;
};

CardLiveFeedEntity.BottomSlot = ({
  tags,
  rarity,
  withRarity,
  colorScheme,
}: CardLiveFeedEntityBottomSlotProps) => {
  if (withRarity) {
    return (
      rarity?.length &&
      rarity?.length > 0 && (
        <TagRarity color={colorScheme[rarity]} variant={rarity} />
      )
    );
  }
  return (
    tags?.length &&
    tags.length > 0 &&
    tags?.map((category, idx) => (
      <TagCategory
        className={styles['tag-category']}
        key={`clfc-${category}-idx-${idx}`}
        variant={category}
      />
    ))
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Item = ({ item, rarity }: CardLiveFeedEntityItemProps) => {
  const colorScheme = itemVariantsMM2.includes(item)
    ? colorsMM2Variant
    : colorsByVariant;

  const className = cn(
    styles['item-rarity'],
    styles[`item-rarity-${itemVariantsMM2.includes(item) ? 'mm2' : 'adopt'}`],
  );

  return (
    <>
      <ItemRarityLiveFeed
        colorScheme={colorScheme}
        size={'58'}
        className={className}
        variant={rarity}
        isMM2Variant={itemVariantsMM2.includes(item)}
      />
      <Item className={styles['item']} size={'96'} variant={item} />
    </>
  );
};
