'use client';
import { CardShoppingItemProps } from '@entities/desktop/cards/card-shopping-item/ui/card-shopping-item.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import {
  GlobalItem,
  GlobalItemInfo,
  GlobalMM2Item,
} from '@/shared/types/common';
import { Divider, Skeleton } from '@/shared/ui';
import { Item } from '@/shared/ui';
import { ItemRarityCard } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { TagCategory } from '@/shared/ui/tag-category';
import { TagRarity } from '@/shared/ui/tag-rarity';
import { Typography } from '@/shared/ui/typography';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './card-shopping-item.module.scss';

export const CardShoppingItemEntity = ({
  children,
  className,
  rarity,
  actionButtons,
  itemPriceSlot,
  itemPropertiesSlot,
  bottomSlot,
  itemInfoSlot,
  itemImageSlot,
  ...props
}: CardShoppingItemProps) => {
  const cardLiveFeedClasses = cn(styles['card-shopping-item'], className);
  const isClient = useIsClient();
  const isHydrated = useStore().app.isHydrated && isClient;

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <div className={styles['item-info']}>
        <div className={styles['content']}>
          {itemImageSlot}

          {itemInfoSlot}

          {itemPropertiesSlot}
        </div>

        <div className={styles['action-btns']}>{actionButtons}</div>
      </div>
      <Divider direction={'vertical'} />
      <div className={styles['price-info']}>
        {isHydrated ? (
          itemPriceSlot
        ) : (
          <Skeleton style={{ width: '100%', height: 40 }} />
        )}
        {bottomSlot}
      </div>
    </article>
  );
};

CardShoppingItemEntity.ItemImageSlot = ({
  item,
  rarity,
  status,
}: Pick<CartItemType, 'item' | 'rarity' | 'status'>) => {
  const colorScheme = itemVariantsMM2.includes(item)
    ? colorsMM2Variant
    : colorsByVariant;

  return (
    <div
      className={cn(styles['item'], {
        [styles['item-unavailable']]: status === 'unavailable',
      })}
    >
      <ItemRarityCard
        colorScheme={colorScheme}
        className={styles['background']}
        size={'75'}
        variant={rarity}
      />
      <Item className={styles['item-variant']} size={'68'} variant={item} />
    </div>
  );
};

CardShoppingItemEntity.ItemInfo = ({
  title,
  age,
}: Omit<GlobalItemInfo, 'price'>) => {
  return (
    <div className={styles['title-block']}>
      {age && <Typography className={styles['item-type']}>{age}</Typography>}
      {title && (
        <Typography className={styles['item-title']}>{title}</Typography>
      )}
    </div>
  );
};

CardShoppingItemEntity.ItemPrice = observer(
  ({ info: { price }, status }: Pick<CartItemType, 'info' | 'status'>) => {
    const currencySign = useStore().app.getCurrencySign;

    if (status === 'unavailable') {
      return (
        <div className={styles['price']}>
          <Typography className={styles['status']}>Not available</Typography>
        </div>
      );
    }

    return (
      <div className={styles['price']}>
        <Typography className={styles['current']}>
          {currencySign} {price.current}
        </Typography>
        {price?.old && (
          <Typography decoration={'line-through'} className={styles['old']}>
            {currencySign} {price.old}
          </Typography>
        )}
      </div>
    );
  },
);

CardShoppingItemEntity.ItemProperties = ({
  rarity,
  tagCategory,
}: Pick<GlobalItem | GlobalMM2Item, 'rarity' | 'tagCategory'>) => {
  return (
    ((Array.isArray(rarity) && rarity.length) ||
      Array.isArray(tagCategory)) && (
      <div className={styles['properties']}>
        {rarity && <TagRarity variant={rarity} />}
        {tagCategory?.map((tag, idx) => {
          return <TagCategory key={`cart-shop-${tag}-${idx}`} variant={tag} />;
        })}
      </div>
    )
  );
};
