import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './item-rarity-shop-card-bg.module.scss';
import { ItemRarityLiveFeedIconSvgProps } from './item-rarity-shop-card-bg.types';

export const colorsByVariant = {
  common: 'var(--global-blue400)',
  uncommon: 'var(--global-purple400)',
  'ultra-rare': 'var(--global-red400)',
  rare: 'var(--global-green400)',
  legendary: 'var(--global-rainmaker400)',
} as const;

export const colorsMM2Variant = {
  common: '#475467',
  uncommon: '#4891FF',
  unique: '#6941C6',
  vintage: '#EB3C3C',
  rare: '#40BB18',
  godly: '#FE9920',
  ancient: '#E0E345',
  legendary: '#D41057',
} as const;

export const ItemRarityShopCardBg = ({
  variant,
  size,
  className,
  style,
  colorScheme,
  isMM2Variant,
  ...props
}: ItemRarityLiveFeedIconSvgProps) => {
  const itemRarityShopCardBgClasses = cn(
    styles['item-rarity-shop-card-bg'],
    styles[`size--${size}`],
    className,
  );

  return (
    <Illustration
      style={{ color: colorScheme[variant], ...style }}
      spanTagClassName={itemRarityShopCardBgClasses}
      name={
        isMM2Variant
          ? 'item-rarity-shop-card-bg-mm2-1'
          : 'item-rarity-shop-card-bg-1'
      }
    />
  );
};
