'use client';
import { CardItemOfferProps } from '@entities/desktop/cards/card-item-offer/ui/card-item-offer.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { Item, Skeleton } from '@/shared/ui';
import { ItemRarityCard } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { TagCategory } from '@/shared/ui/tag-category';
import { TagRarity } from '@/shared/ui/tag-rarity';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './card-item-offer.module.scss';
export const CardItemOfferEntity = observer(
  ({
    children,
    className,
    bottomSlot,
    priceInfo: { old, current },
    item,
    tagsRarity,
    tagsCategory,
    rarity,
    ...props
  }: CardItemOfferProps) => {
    const cardLiveFeedClasses = cn(styles['card-item-offer'], className);
    const currencySign = useStore().app.getCurrencySign;

    const isClient = useIsClient();
    const isHydrated = useStore().app.isHydrated && isClient;

    const colorScheme = itemVariantsMM2.includes(item)
      ? colorsMM2Variant
      : colorsByVariant;

    return (
      <article className={cardLiveFeedClasses} {...props}>
        <div className={styles['content']}>
          <div className={styles['price-info-container']}>
            <div className={styles['price-info']}>
              {isHydrated ? (
                <>
                  <Typography className={styles['current']}>
                    {currencySign}
                    {current}
                  </Typography>
                  <Typography
                    className={styles['old']}
                    decoration={'line-through'}
                  >
                    {currencySign}
                    {old}
                  </Typography>
                </>
              ) : (
                <Skeleton style={{ width: 60, height: 32 }} />
              )}
            </div>
            {((Array.isArray(tagsRarity) && tagsRarity.length) ||
              (Array.isArray(tagsCategory) && tagsCategory.length)) && (
              <div className={styles.properties}>
                {tagsRarity?.map((tag, idx) => {
                  return (
                    <TagRarity
                      size={18}
                      key={`item-offer-${tag}-${idx}`}
                      variant={tag}
                    />
                  );
                })}
                {tagsCategory?.map((tag, idx) => {
                  return (
                    <TagCategory
                      size={18}
                      key={`item-offer-${tag}-${idx}`}
                      variant={tag}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {bottomSlot}
        </div>
        <div className={styles['item']}>
          <ItemRarityCard
            colorScheme={colorScheme}
            className={styles['background']}
            style={{ position: 'absolute', zIndex: 0 }}
            size={'56'}
            variant={rarity}
          />
          <Item
            className={styles['item--variant']}
            style={{
              zIndex: 1,
              pointerEvents: 'none',
              transition: 'all 0.15s ease-in-out',
            }}
            size={'48'}
            variant={item}
          />
        </div>
      </article>
    );
  },
);
